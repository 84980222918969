<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackbarColor"
    >
    {{ snackbarText }}
    </v-snackbar>
    <v-row>
      <v-spacer />
      <v-col :cols="$isMobile()? 12 : 10">
    <v-card
      v-for="(board,i) in boards"
      :key="i"
      max-width="1000"
      min-height="250"
      class="mb-4"
      elevation="10"
    >
      <v-card-title
        class="mb-3 grey py-3"
        dark
      >
        {{ board.name }}
      </v-card-title>
      <v-card-text>
        <container
          lock-axis="y"
          drag-class="card-ghost"
          drop-class="card-ghost-drop"
          @drop="(e) => onDrop(i, e)"
        >
          <draggable
            v-for="(member,j) in board.components"
            :key="j"
          >
            <v-card
              class="draggable-item mb-1 mt-1 px-2"
              max-height="80"
              height="50"
              outlined
              color="accent"
            >
              <v-row>
                <v-col
                  cols="5"
                >
                  {{ member.firstname }} {{ member.lastname }}
                </v-col>
                <v-col
                  cols="5"
                >
                  {{ member.role }}
                </v-col>
                <v-col
                  cols="1"
                >
                  <v-btn
                    icon
                    @click="removeMember(i,j)"
                  >
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-card>
          </draggable>
        </container>
      </v-card-text>
      <v-card-text>
        <v-row
          justify="center"
        >
          <v-spacer />
          <v-col
            cols="4"
          >
          <v-autocomplete
            :ref="'member' + i"
            v-model="selectedUser[i]"
            :items="members"
            item-text="name"
            item-value="id"
            label="Aggiungi membro"
            outlined
            :filter="customFilter"
            clearable
          >
            <template
              slot="selection"
              slot-scope="data"
            >
              {{ data.item.firstname }} {{ data.item.lastname }}
            </template>
            <template
              slot="item"
              slot-scope="data"
            >
              {{ data.item.firstname }} {{ data.item.lastname }}
            </template>
          </v-autocomplete>
          </v-col>
          <v-col
            cols="3"
          >
          <v-text-field
            id="role"
            :ref="'role' + i"
            type="text"
            outlined
            placeholder="Ruolo"
            label="Ruolo"
          />
          </v-col>
          <v-col
            cols="1"
          >
            <v-btn
              @click="addMember(i)"
            >
              Aggiungi
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col
            cols="2"
          >
          <v-btn
            @click="saveBoard(i)"
          >
            Salva
          </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-card-text>
    </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>
<script>
  import { Container, Draggable } from 'vue-smooth-dnd'
  const config = require('@/config')
  const axios = require('axios')
  export default {
    name: 'Boards',
    components: { Container, Draggable },
    data: () => ({
      boards: [],
      members: [],
      selectedUser: [],
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
    }),
    created: function () {
      var component = this
      var currentJWT = localStorage.getItem(config.authToken)
      axios
        .get(config.backendURL + '/adminboards', {
          headers: {
            authorization: 'Bearer ' + currentJWT,
          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            component.boards = response.data.boards
            component.boards.forEach(function (e) {
              component.selectedUser.push(null)
            })
            localStorage.setItem(config.authToken, response.data.jwt)
          }
        })
        .catch(function (response) {
          console.log(response)
        })
      axios
        .get(config.backendURL + '/members', {
          headers: {
            authorization: 'Bearer ' + currentJWT,
          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            component.members = response.data.members
            localStorage.setItem(config.authToken, response.data.jwt)
          }
        })
        .catch(function (response) {
          console.log(response)
        })
    },
    methods: {
      customFilter (item, queryText, itemText) {
        const textOne = item.firstname.toLowerCase()
        const textTwo = item.lastname.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
      saveBoard (boardindex) {
        var currentJWT = localStorage.getItem(config.authToken)
        var component = this
        axios
          .put(config.backendURL + '/boards/' + component.boards[boardindex].id, component.boards[boardindex], {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snackbarText = 'Composizione dell\'organo salvata correttamente'
              component.snackbarColor = 'primary'
              component.snackbar = true
            } else {
              component.snackbarText = response.data.longMessage
              component.snackbarColor = 'error'
              component.snackbar = true
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      removeMember (boardindex, memberindex) {
        var component = this
        component.boards[boardindex].components.splice(memberindex, 1)
        component.selectedUser[boardindex] = null
        component.$refs['role' + boardindex][0].reset()
      },
      addMember (boardindex) {
        var component = this
        var role = component.$refs['role' + boardindex][0].lazyValue
        component.members.forEach(function (member) {
          if (member.id === component.selectedUser[boardindex]) {
            component.boards[boardindex].components.push({
              firstname: member.firstname,
              lastname: member.lastname,
              id: member.id,
              role: role,
              listorder: component.boards[boardindex].components.length + 1,
            })
          }
        })
        component.selectedUser[boardindex] = null
        component.$refs['role' + boardindex][0].reset()
      },
      onDrop (boardid, dropResult) {
        var component = this
        const { removedIndex, addedIndex, payload } = dropResult
        if (dropResult.removedIndex !== dropResult.addedIndex) {
          var result = [...component.boards[boardid].components]
          let itemToAdd = payload
          if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0]
            // console.log(itemToAdd)
            // console.log(result)
          }
          if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd)
            // console.log(result)
          }
          component.boards[boardid].components = result
          var counter = 0
          component.boards[boardid].components.forEach(element => {
            counter += 1
            element.listorder = counter
          })
        }
      },
    },
  }
</script>
